@import "styles/variables";
@import "styles/animations";

.App {
    text-align: center;
}

.container-home {
    width: 100%;
    .section:nth-child(even) {
        background-color: $backgroundLight;
    }

    .text-enter-up {
        animation: enter-up 2s ease 0s 1 normal forwards;
    }
    .text-enter-down {
        opacity: 0;
        animation: enter-down 2s ease 0.5s 1 normal forwards;
    }

    section.section {
        .title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;

            .section-title {
                font-family: "Cormorant", serif;
                position: relative;
                text-transform: uppercase;
                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 5rem;
                    height: 3px;
                    border-bottom: 1px solid black;
                    position: absolute;
                    top: 50%;
                    @media (max-width: 767.98px) {
                        width: 3rem;
                    }
                }
                &:before {
                    left: -6rem;
                    @media (max-width: 767.98px) {
                        left: -4rem;
                    }
                }
                &:after {
                    right: -6rem;
                    @media (max-width: 767.98px) {
                        right: -4rem;
                    }
                }
            }
            .section-subtitle {
                font-family: "Montserrat", sans-serif;
            }
        }
    }
    .image-container-home {
        position: relative;
        text-align: center;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        .picture {
            position: absolute;
            width: 100%;
            background: url("pics/home.jpg") no-repeat center center;
            height: 100vh;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
        .image-slideshow {
            width: 100%;
            top: 0;
            left: 0;
            max-width: 1000px;
            position: relative;
            margin: auto;
            height: 100vh;
            .fade {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                animation-name: fade;
                animation-duration: 2s;
                img {
                    width: 100%;
                    flex-shrink: 0;
                    min-width: 100%;
                    min-height: 100%;
                }
            }
            @keyframes fade {
                from {
                    opacity: 0.7;
                }
                to {
                    opacity: 1;
                }
            }
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .navbar {
            opacity: 0;
            animation: show-up 2s ease 2s 1 normal forwards;
            position: absolute;
            top: 1.2rem;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            list-style-type: none;
            margin: 0;
            li {
                &:not(:first-of-type) {
                    border-left: 1px solid #fff;
                }
            }
            li a,
            li a:after,
            li a:before {
                text-decoration: none;
                transition: all 0.5s;
            }
            li a {
                position: relative;
                padding: 0 7px;
                margin: 0 15px;
                max-width: 100%;
            }
            li a:after {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 0%;
                content: ".";
                color: transparent;
                background: #aaa;
                height: 1px;
            }
            li a:hover:after {
                width: 100%;
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .centered {
            position: absolute;
            height: 75vh;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 60px;
            h1.names {
                font-size: 65px;
                @media (max-width: 575.98px) {
                    font-size: 50px;
                }
            }
            .text-flex {
            }
        }
    }

    .image-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            transition: none !important;
        }
        img:hover {
            transform: none !important;
        }
        .centered {
            position: absolute;
        }
        button.btn-one {
            transition: all 0.4s ease-out;
            color: #001f3f;
            border: 1px solid white;
            text-transform: uppercase;
            text-align: center;
            line-height: 1;
            font-size: 25px;
            font-weight: 500;
            background-color: #fff;
            padding: 30px;
            outline: none;
            border-radius: 4px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);

            &:hover {
                color: #001f3f;
                background-color: #fff;
                transform: scale(1.05);

            }

            @media (max-width: 767.98px) {
                font-size: 15px;
                font-weight: 500;
                padding: 15px;
            }

        }
    }

    .parallax-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 450px;
        width: 100%;
        .parallax {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-attachment: fixed;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            .overlay-title {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h1,
                h4 {
                    color: white;
                }
            }
        }
    }

    .image-container-location {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2rem 0;
        @media (max-width: 575.98px) {
            padding: 2rem 12px;
        }
        .location-images {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                max-height: 320px;
                width: auto;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                    0 6px 20px 0 rgba(0, 0, 0, 0.19);
                @media (max-width: 1399.98px) {
                    max-height: 270px;
                }
                @media (max-width: 1199.98px) {
                    max-height: 230px;
                }
                @media (max-width: 991.98px) {
                    max-width: 49%;
                }
                @media (max-width: 767.98px) {
                    max-width: 49%;
                }
            }
            @media (max-width: 767.98px) {
                .dsmnone {
                    display: none;
                }
            }
            @media (max-width: 991.98px) {
                .dsmnone {
                    display: none;
                }
            }
        }
        .location-text {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 1rem;
            padding: 0;
            h4 {
                font-size: 25px;
                font-weight: 500;
            }
            strong {
                @media (max-width: 767.98px) {
                    font-size: 15px;
                    margin-bottom: 5px;
                }
            }
            .links {
                font-size: 18px;
            }
        }
    }
    .iframe-map {
        width: 100%;
        height: 300px;
        @media (max-width: 767.98px) {
            height: 180px;
        }
    }

    .calendar-div-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4rem 0;
        img {
            max-height: 240px;
            width: auto;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);

            &.calendar {
                max-height: 290px;
                @media (max-width: 1399.98px) {
                    max-height: 260px;
                }
                @media (max-width: 1199.98px) {
                    max-height: 220px;
                }
            }
            @media (max-width: 1399.98px) {
                max-height: 220px;
            }
            @media (max-width: 1199.98px) {
                max-height: 180px;
            }
        }

        @media (max-width: 991.98px) {
            display: none;
        }

        @media (max-width: 767.98px) {
            display: none;
        }
    }
    .calendar-container-div-mobile {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 1rem 0;
        .row1 {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.75rem 0;

            img {
                width: auto;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                    0 6px 20px 0 rgba(0, 0, 0, 0.19);
                @media (max-width: 575.98px) {
                    max-width: 50%;
                }
                @media (max-width: 991.98px) {
                    max-width: 50%;
                }

                @media (max-width: 767.98px) {
                    max-width: 50%;
                }
            }
        }
        .row2 {
            padding: 0.75rem 0;
            img {
                height: auto;
                width: 100%;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                    0 6px 20px 0 rgba(0, 0, 0, 0.19);
                @media (max-width: 575.98px) {
                }
            }
        }
    }
    .calendar-name-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .calendar-name {
            margin-top: 30px;
            font-size: 100px;
            @media (max-width: 767.98px) {
                font-size: 60px;
                margin-top: 15px;
                margin-bottom: 5px;
            }
        }
    }

    .text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .kumove {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $backgroundLight;
            width: 100%;
            padding: 50px 250px;

            .kumove-title {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                h3 {
                    font-weight: 400;
                    padding: 35px 0px;
                }
                h4 {
                    font-weight: 500;
                }
                .text-green {
                }
            }
            .image-container-cropped {
                background-color: $backgroundLight;
                // color: white;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                img {
                    width: 600px;
                    height: auto;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                        0 6px 20px 0 rgba(0, 0, 0, 0.19);
                }
            }
        }

        .text-container-paragraph {
            width: 100%;
            padding: 50px 0px;
            @media (max-width: 767.98px) {
                padding: 25px 0px;
            }
            .container {
            }
        }
    }

    .gallery-container-div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5rem 0;
        img {
            max-height: 250px;
            width: auto;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19);
            @media (max-width: 1399.98px) {
                max-height: 220px;
            }
            @media (max-width: 1199.98px) {
                max-height: 180px;
            }
        }
        @media (max-width: 991.98px) {
            display: none;
        }

        @media (max-width: 767.98px) {
            display: none;
        }
    }
    .gallery-container-div-mobile {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 1rem 0;
        .row1 {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.75rem 0;

            img {
                width: auto;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                    0 6px 20px 0 rgba(0, 0, 0, 0.19);
                @media (max-width: 575.98px) {
                    max-width: 50%;
                }
                @media (max-width: 991.98px) {
                    max-width: 50%;
                }

                @media (max-width: 767.98px) {
                    max-width: 50%;
                }
            }
        }
        .row2 {
            padding: 0.75rem 0;
            img {
                height: auto;
                width: 100%;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                    0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }
        }
    }

    .montserrat {
        font-family: "Montserrat", sans-serif;
    }
    .cormorant {
        font-family: "Cormorant", serif;
    }
    .caravan {
        font-family: "Caravan";
    }
    .nautugal {
        font-family: "The Nautigal", cursive;
    }
    .caveat {
        font-family: "Caveat", cursive;
    }
    img {
        transition: all 0.4s ease-in-out;
    }
    img:hover {
        transform: scale(1.05);
    }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
}
