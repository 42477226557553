@keyframes enter-up {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateY(-120px);
  }

  50% {
    animation-timing-function: ease-out;
    opacity: 0.5;
    transform: translateY(-60px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes enter-down {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: translateY(120px);
  }

  50% {
    animation-timing-function: ease-out;
    opacity: 0.5;
    transform: translateY(60px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-up {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
  }
}