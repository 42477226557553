@import "../styles/variables";

.modal {
    .modal-header {
        padding: 25px;
        background-color: $backgroundLight;
        h5 {
            // text-transform: uppercase;
        }
    }
    .modal-body {
        padding: 25px;
        .form-attendance {
            margin: 1rem 0;

            label.label-title {
                margin-bottom: 8px;
                font-weight: 500;
            }
            input[type="radio"]:checked,
            input[type="checkbox"] {
                accent-color: #000000;
                background-color: #000000;
                border-color: #000000;
            }
            input[type="radio"]:focus,
            input[type="checkbox"]:focus {
                box-shadow:none;
            }
            .check {
                display: flex;
                flex-direction: column;
                .check-input {
                    padding-left: 0.8rem;
                    @media (max-width: 575.98px) {
                        margin-left: 0;
                    }
                    label {
                        margin-left: 5px;
                    }
                }
            }
            .radio {
                display: flex;
            }
            .radio-accommodation {
                .radio-input{
                    @media (max-width: 575.98px) {
                        margin-left: 0;
                    }
                }
                @media (max-width: 575.98px) {
                    flex-direction: column;
                    margin-left: 0;
                }
            }
        }
        .nav-tabs {
            .nav-item {
                button.nav-link:not(.active) {
                    color: #553d1c;
                }
            }
        }
        .tab-content > .tab-pane {
            padding: 1rem;
            border: 1px solid rgb(222, 226, 230);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-top: none;
        }
    }
    .modal-footer {
        background-color: $backgroundLight;
        display: flex;
        justify-content: center;
        .footer-text-container {
            margin-bottom: 1rem;
        }
        .btn-container {
            width: 100%;
            display: flex;
            justify-content: center;

            .btn-confirm {
                width: 225px;
            }
        }
    }
    .required-field::after {
        content: "*";
        color: red;
    }
    label {
        @media (max-width: 575.98px) {
            font-size: 14.5px;
        }
    }

    .spinner {
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 3px solid rgba(255,255,255,.3);
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
    }

    @keyframes spin {
        to { -webkit-transform: rotate(360deg); }
    }
    @-webkit-keyframes spin {
        to { -webkit-transform: rotate(360deg); }
    }
}
