html {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    h1 {
        font-size: 50px;
        margin: 0px;
    }
    h2 {
        font-size: 3.4rem;
        margin: 0px;
        @media (max-width: 1199.98px) {
            font-size: 2.5rem;
        }
        @media (max-width: 767.98px) {
            font-size: 2rem;
        }
    }
    h3 {
        font-size: 30px;
        font-weight: 400;
        margin: 0px;
        @media (max-width: 1199.98px) {
            font-size: 25px;
        }
        @media (max-width: 991.98px) {
            font-size: 22px;
        }
        @media (max-width: 767.98px) {
            font-size: 20px;
        }
    }
    h4 {
        font-size: 25px;
        font-weight: 400;
        margin: 0px;

        @media (max-width: 767.98px) {
            font-size: 15px;
        }
    }
    h5 {
        @media (max-width: 575.98px) {
            font-size: 1rem;
        }
    }
    h6 {
        @media (max-width: 575.98px) {
            font-size: 0.8rem;
        }
    }
    p {
        font-size: 18px;
        @media (max-width: 1199.98px) {
            font-size: 17px;
        }
        @media (max-width: 991.98px) {
            font-size: 15px;
        }
        @media (max-width: 767.98px) {
            font-size: 15px;
        }
        /* @media (max-width: 575.98px) {
            font-size: 13px;
        } */
    }
    a {
        color: black;
        @media (max-width: 767.98px) {
            font-size: 15px;
        }
    }
    a.nav {
        color: white;
        @media (max-width: 767.98px) {
            font-size: 13px;
        }
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
