@import "../styles/variables";

.program-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .graphic-container {
        width: 100%;
        padding: 3rem 0;
        .graphic-row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .graphic-row-left {
                border-right: 2px solid black;
                width: 50%;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @media (max-width: 575.98px) {
                    height: 110px;
                    border-width: 1px;
                }
                .hour {
                    h2 {
                        @media (max-width: 1199.98px) {
                            padding-bottom: 15px;
                        }
                        @media (max-width: 767.98px) {
                            padding-bottom: 15px;
                        }
                        @media (max-width: 575.98px) {
                            padding-bottom: 15px;
                            font-size: 1.6rem;
                        }
                        @media (max-width: 398px) {
                            font-size: 1.4rem;
                        }
                    }
                }
                .location {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    flex-direction: column;
                    width: 42%;
                    margin-left: 15px;
                    @media (max-width: 991.98px) {
                        width: 50%;
                    }
                    @media (max-width: 767.98px) {
                        width: 60%;
                    }
                    @media (max-width: 575.98px) {
                        width: 68%;
                        margin-left: 8px;
                    }
                    h3 {
                        border-bottom: 3px solid black;
                        width: 100%;
                        padding-left: 20px;
                        @media (max-width: 575.98px) {
                            padding-left: 10px;
                            border-width: 2px;
                        }
                        @media (max-width: 398px) {
                            font-size: 18px;
                        }
                    }
                    p {
                        width: 100%;
                        padding-left: 20px;
                        @media (max-width: 575.98px) {
                            font-size: 13px;
                            padding-left: 10px;
                        }
                        @media (max-width: 398px) {
                            font-size: 11px;
                        }
                    }
                }
            }
            .graphic-row-right {
                border-left: 2px solid black;
                width: 50%;
                height: 120px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                padding-left: 15px;
                @media (max-width: 575.98px) {
                    height: 110px;
                    border-width: 1px;
                }
                img {
                    height: 55%;
                    @media (max-width: 575.98px) {
                        height: 45%;
                    }
                }
                .turn-white {
                    // filter: brightness(0) invert(1);
                }
            }
        }
        .reversed {
            display: flex;
            flex-direction: row-reverse;
            .graphic-row-left {
                justify-content: flex-end;
                border-left: 2px solid black;
                border-right: none;

                @media (max-width: 575.98px) {
                    border-width: 1px;
                }

                .location {
                    align-items: flex-start;
                    margin-right: 15px;
                    margin-left: 0px;
                    @media (max-width: 575.98px) {
                        margin-right: 8px;
                    }
                    h3,
                    p {
                        padding-right: 20px;
                        padding-left: 0px;
                        text-align: right;
                    }
                    h3 {
                        @media (max-width: 575.98px) {
                            padding-right: 10px;
                        }
                    }
                    p {
                        @media (max-width: 575.98px) {
                            font-size: 13px;
                            padding-right: 10px;
                        }
                    }
                }
            }
            .graphic-row-right {
                align-items: flex-end;
                border-right: 2px solid black;
                border-left: none;
                padding-right: 15px;

                @media (max-width: 575.98px) {
                    border-width: 1px;
                }

            }
        }
    }
}
